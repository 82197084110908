const logOutRoute = {
  title: "Logout",
  icon: require("@/assets/icons/Logout.svg"),
  route: "Login",
};

const documents = {
  title: "Documents",
  icon: require("@/assets/icons/documentation-icon.svg"),
  children: [
    {
      title: "Quick Start Guide",
      external: true,
      link: "https://www.mastermindsports.com/quick-start-guide",
    },
    {
      title: "Training Program",
      external: true,
      link: "https://www.mastermindsports.com/training-program-overview",
    },
    {
      title: "Assessment Overview",
      external: true,
      link: "https://www.mastermindsports.com/assessment-overview",
    },
    {
      title: "Terms of Service",
      external: true,
      link: "https://www.mastermindsports.com/terms-of-service",
    },
    {
      title: "Privacy Policy",
      external: true,
      link: "https://www.mastermindsports.com/privacy-policy",
    },
  ],
};

export const internalUserRoutes = {
  topRoutes: [
    {
      title: "Dashboard",
      icon: require("@/assets/icons/dashboard-icon.svg"),
      route: "Home",
    },
    {
      title: "Manage Organization Users",
      icon: require("@/assets/icons/users-icon.svg"),
      route: "Manage players",
    },
    documents,
    {
      title: "Assessment Tracking",
      icon: require("@/assets/icons/assessment-icon.svg"),
      children: [
        {
          title: "Compare by individual user",
          route: "User Assessment Scores",
        },
        {
          title: "Compare by assessment",
          route: "Assessment overview",
        },
      ],
    },
    {
      title: "Account",
      icon: require("@/assets/icons/profile-icon.svg"),
      route: "User Payments",
    },
    {
      title: "Settings",
      icon: require("@/assets/icons/settings-icon.svg"),
      route: "Settings Profile",
    },
    
  ],
  bottomRoutes: [
    logOutRoute,
  ],
};

export const generalUserRoutes = {
  topRoutes: [
    {
      title: "Games Overview",
      icon: require("@/assets/icons/documentation-icon.svg"),
      route: "Games VR",
    },
    documents,
    {
      title: "Assessment",
      icon: require("@/assets/icons/assessment-icon.svg"),
      route: "Assessment Report",
    },
    {
      title: "Account Settings",
      icon: require("@/assets/icons/profile-icon.svg"),
      children: [
        {
          title: "Personal Information",
          route: "Settings Profile",
        },
        {
          title: "Players",
          route: "Players",
        },
      ],
    },
  ],
  bottomRoutes: [
    logOutRoute,
  ],
};

export const adminUserRoutes = {
  topRoutes: [
    {
      title: "Dashboard",
      icon: require("@/assets/icons/dashboard-icon.svg"),
      route: "Home",
    },
    {
      title: "Organization Management",
      icon: require("@/assets/icons/location-admin-icon.svg"),
      route: "Location Admin",
    },
    {
      title: "Dashboards",
      icon: require("@/assets/icons/assessment-icon.svg"),
      children: [
        {
          title: "B2B dashboard",
          route: "B2B dashboard",
        },
        {
          title: "B2C dashboard",
          route: "User Assessment Scores",
        },
      ],
    },
    {
      title: "Financial Analysis Report",
      icon: require("@/assets/icons/documentation-icon.svg"),
      route: "Financial Report",
    },
    {
      title: "Settings",
      icon: require("@/assets/icons/settings-icon.svg"),
      route: "Settings Profile",
    },
  ],
  bottomRoutes: [
    logOutRoute,
  ],
};
