<template>
  <div
    class="flex flex-col sm:flex-row items-center justify-center py-3 px-4 bg-blue-900 border-4 text-white text-sm font-bold"
    :style="{ borderColor: '#FF1E59', backgroundColor: '#1D2E48' }"
    role="alert"
  >
    <div class="flex items-center">
      <img
        src="@/assets/icons/alert.svg"
        alt="Free trial alert"
        class="w-5 h-5 mr-1 fill-current"
      />
      <span :style="{ color: '#FF1E59' }">IMPORTANT:</span>
    </div>
    <div class="mt-2 sm:mt-0 sm:ml-2 text-center sm:text-left">
      <span
        >{{ message }}
        <a
          @click="handleContinue"
          :style="{ color: '#edfe86', cursor: 'pointer' }"
          class="text-color underline"
          >Click here</a
        ></span
      >
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { createClient } from "@/services/stripe/stripe.js";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

const store = useStore();
const router = useRouter();

const user = computed(() => store.getters["user/getUserData"]);

const props = defineProps({
  message: {
    type: String,
    required: true,
  },
});

async function handleContinue() {
  const email = user.value["email"];
  const phoneNumber = user.value["phoneNumber"];
  const userName = user.value["userName"];

  const stripeClient = await createClient(email, userName, true);

  router.replace({
    name: "Check Out",
    query: {
      customerId: stripeClient.data.customer.id,
      email,
      phoneNumber,
      isFreeTrial: true,
    },
  });
  removeLastHistoryEntry()
}

function removeLastHistoryEntry() {
  window.history.pushState(null, '', window.location.href);
  window.history.go(1);
}
</script>
